/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-11",
    versionChannel: "nightly",
    buildDate: "2024-01-11T00:15:08.420Z",
    commitHash: "629e8f89ab79afc5ed44cb5888b80ee442ed254e",
};
